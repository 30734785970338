import { useState } from 'react';
import { InputNumber, InputNumberProps } from 'antd';
import clsx from 'clsx';

import floatStyles from '../index.module.scss';
import styles from './index.module.scss';

interface FloatNumberProps extends Omit<InputNumberProps, 'value' | 'onChange'> {
  label: string;
  value?: number | undefined
  controls?: boolean
  max?: number | undefined
  required?: boolean;
  onChange?: InputNumberProps['onChange']; // (value: number | null) => void;
  addon?: string | undefined
  disable?: boolean
  result? : number | undefined
  precision?: number | undefined
}

function FloatNumber({
  label, value, required, onChange, max, controls, addon, disable, result, precision, ...rest
}: FloatNumberProps) {
  const [focus, setFocus] = useState(false);
  const isOccupied = focus || (value || value === 0 || result || result === 0);

  return (
    <label
      className={floatStyles.floatLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <InputNumber
        className={clsx(floatStyles.input, styles.input)}
        variant="borderless"
        size="large"
        onChange={disable ? undefined : onChange}
        max={max}
        min={0}
        precision={precision}
        value={result || (value || undefined)}
        controls={controls}
        addonAfter={addon}
        disabled={disable}
        {...rest}
      />
      <span className={clsx(floatStyles.label, isOccupied ? floatStyles.asLabel : floatStyles.asPlaceholder)}>
        {isOccupied ? label : label}
        {' '}
        {required ? <span className="text-danger">*</span> : null}
      </span>
    </label>
  );
}

FloatNumber.defaultProps = {
  value: '',
  required: false,
  onChange: () => {},
  max: undefined,
  controls: false,
  disable: false,
  addon: undefined,
  result: undefined,
};

export default FloatNumber;
