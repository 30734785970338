import React, {
  CSSProperties,
  useEffect, useRef, useState,
} from 'react';
import {
  Form, InputNumber, message, Modal, Tooltip,
} from 'antd';
import { ProTable } from '@ant-design/pro-components';
import { ActionType, ProColumns } from '@ant-design/pro-table/es/typing';
import {
  CheckOutlined, CloseOutlined, PlusOutlined,
} from '@ant-design/icons';
import { NamePath, Rule } from 'rc-field-form/lib/interface';
import clsx from 'clsx';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { Good, PackageItem, PackagesData } from '../../../../../../../../hooks/api/order';
import { IconDangerousOutlined } from '../../../../../../../Common/Icon';
import { GoodsFormProp } from '../../index';

import styles from './index.module.scss';

const countGoodsItemOccurrences = (
  packages: PackagesData[],
  goodsItemId: string,
): number => (packages || []).reduce((total, pkg) => (
  total
      + (pkg.items || []).reduce((itemTotal, item) => {
        if (item.goodsItem.id === goodsItemId) {
          return itemTotal + item.quantity;
        }

        return itemTotal;
      }, 0)
), 0);

interface InputSelectGoodForPackageProps extends GoodsFormProp {
  index: number; // name
  name: NamePath<string>;
  validationRules?: Rule;
  required?: boolean;
}
export default function InputSelectGoodForPackage({
  index,
  goodsForm,
  name,
  required = false,
  // validationRules,
}: InputSelectGoodForPackageProps) {
  const actionRef = useRef<ActionType | undefined>();
  const form = useFormInstance();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<PackageItem[]>([]);

  const packagesFormWatch: PackagesData[] = Form.useWatch('packages', form) || [];
  const inputValueWatch: PackageItem[] = Form.useWatch(['packages', index, 'items'], form);

  /** Products form */
  const [dataSource, setDataSource] = useState<Good[]>([]);

  useEffect(() => {
    if (isModalOpen) {
      setDataSource(goodsForm.getFieldsValue()?.goods || []);
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (dataSource) {
      const productsValue = form.getFieldValue(['packages', index, 'items']);

      setSelectedProducts(productsValue || []);
    }
  }, [dataSource]);

  const handleSelectedProducts = (quantity: number | null, entity: Good) => {
    const { id } = entity;

    if (quantity) {
      if (id) {
        setSelectedProducts((prevState) => {
          const currentItem = prevState?.find((i) => (i?.goodsItem?.id === id));

          /** If item already was added - then update it (to keep same ID) */
          if (currentItem) {
            return [
              ...(prevState || []).filter((i) => i?.goodsItem?.id !== id),
              { ...currentItem, quantity },
            ];
          }

          /** Else create new item */
          return [
          /** If on select we already have some selected items - ignore they on new 'add' action  */
            ...prevState, // .filter((i) => i?.goodsItem?.id !== id),
            {
              // id: i.id || undefined,
              quantity,
              goodsItem: entity,
            },
          ];
        });
      } else {
        message.error('Goods item has no ID. Save the goods and then try adding again.', 10);
      }
    } else {
      /** If no quantity remove this item from selected products */
      setSelectedProducts((prevState) => prevState
        .filter((pack) => pack?.goodsItem?.id !== id));
    }
  };

  const columns: ProColumns<Good>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, row) => row.name || row.description,
    },
    {
      title: 'Kind of Package',
      dataIndex: 'packagingGroup',
      key: 'packagingGroup',
      width: '200px',
    },
    {
      title: 'Weight',
      dataIndex: 'gross',
      key: 'gross',
      renderText: (text) => (text ? `${text} kg` : ''),
    },
    {
      title: 'Type',
      dataIndex: 'dangerousGoods',
      key: 'dangerousGoods',
      renderText: (_, entity) => (
        entity.dangerousGoods ? (
          <span title="Dangerous goods" style={{ fontSize: '24px' }}><IconDangerousOutlined /></span>
        ) : null
      ),
    },
    {
      title: 'Packed',
      dataIndex: 'packed',
      key: 'packed',
      renderText: (_, entity) => {
        const countOccurrences: number = countGoodsItemOccurrences(packagesFormWatch, entity?.id || '');

        if (countOccurrences && countOccurrences >= entity.quantity) {
          return <CheckOutlined style={{ color: '#52C41A' }} title="All items packed" />;
        }

        if (countOccurrences >= 1 && countOccurrences <= entity.quantity) {
          return <CheckOutlined style={{ color: '#FAAD14' }} title="Partially packed" />;
        }

        return null;
      },
    },
    {
      title: 'Total',
      dataIndex: 'quantity',
      key: 'quantity',
      renderText: (_, entity) => (entity.quantity),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '120px',
      render: (_, entity) => (
        <div
          key={entity?.id}
          role="button"
          tabIndex={-1}
          aria-label="input-holder"
          onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
        >
          <InputNumber
            min={0}
            placeholder="0"
            precision={0}
            defaultValue={selectedProducts.find((pack) => pack?.goodsItem?.id === entity.id)?.quantity}
            onChange={(value) => handleSelectedProducts(value, entity)}
            onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}
          />
        </div>
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    // setSelectedProducts([]);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    // setSelectedProducts([]);
  };

  const handleSideCalcActions = (selectedItems: PackageItem[]) => {
    /** Update weight after update of package */
    const updatedWeight = selectedItems
      .reduce((total, curr) => (
        total + ((curr.goodsItem?.gross || 0) * (curr?.quantity || 0))
      ), 0)
      .toFixed(3);

    form.setFieldValue(['packages', index, 'weight'], Number.parseFloat(updatedWeight));

    /** Update pricing */
    const updatedValue = selectedItems
      .reduce((total, curr) => (
        total + ((curr.goodsItem?.value || 0) * (curr?.quantity || 0))
      ), 0)
      .toFixed(3);

    form.setFieldValue(['packages', index, 'value'], Number.parseFloat(updatedValue));
  };

  const validateField = () => form.validateFields([['packages', index, 'items']]);

  const handleAddItems = (selectedItems: PackageItem[]) => {
    form.setFieldValue(['packages', index, 'items'], selectedItems);

    /** Update weight after update of package */
    handleSideCalcActions(selectedItems);

    // triggerValidationAllForm();
    handleOk();
    validateField();
  };

  const handleDeleteItem = (goodsId: string) => {
    const prevVal: PackageItem[] = form.getFieldValue(['packages', index, 'items']);

    const newVal: PackageItem[] = (prevVal || [])
      ?.filter((i) => (
        i?.goodsItem?.id !== goodsId
      ));

    form.setFieldValue(['packages', index, 'items'], newVal);
    setSelectedProducts((prevState) => prevState
      .filter((pack) => (
        pack?.goodsItem?.id !== goodsId
      )));
    handleSideCalcActions(newVal);
    validateField();
  };

  const isProductsError = form.getFieldError(['packages', index, 'items']);

  return (
    <>
      <Form.Item
        label="Products"
        name={[index, 'items']}
        rules={[{ required, message: 'Please select products' }]}
      >
        {/** There created custom value display component, without any inputs. all data saved in form */}
        <div className={clsx(styles.productsWrapper, { [styles.error]: isProductsError?.length })}>
          <div className={styles.mainContent}>
            {(inputValueWatch || [])
              .map((i) => (
                <div
                  key={i.goodsItem?.id}
                  className={styles.productItem}
                >
                  <span>
                    <b>
                      {i?.quantity}
                      {'x '}
                    </b>
                    {i?.goodsItem?.name || i?.goodsItem?.description}
                  </span>

                  <div
                    role="button"
                    tabIndex={-1}
                    aria-label="delete-button"
                    className={styles.deleteIcon}
                    onClick={() => handleDeleteItem(i?.goodsItem?.id || '')}
                  >
                    <CloseOutlined />
                  </div>
                </div>
              ))}
          </div>
          <Tooltip
            placement="top"
            title="Choose a product from this order."
            className={styles.addonAfter}
          >
            <PlusOutlined
              disabled={false} //= {orderUpdate.loading}
              onClick={(e) => {
                e.preventDefault();
                showModal();
              }}
            />
          </Tooltip>
        </div>
      </Form.Item>
      <Modal
        centered
        title="Product Selection"
        open={isModalOpen}
        onCancel={handleCancel}
        okText="Add"
        onOk={() => handleAddItems(selectedProducts)}
        width={1000}
        className={styles.modalTable}
        destroyOnClose
      >
        <div className={styles.tableContainer}>
          <ProTable<Good>
            actionRef={actionRef}
            columns={columns}
            dataSource={dataSource}
            search={false}
            rowKey="key"
            headerTitle="List of Products"
            pagination={{
              defaultPageSize: 10,
              size: 'default',
              showQuickJumper: true,
              showTotal: undefined,
            }}
            options={{
              density: false,
              reloadIcon: false,
              setting: false,
            }}
          />
        </div>

      </Modal>
    </>
  );
}
