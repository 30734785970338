import React, { useEffect } from 'react';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import {
  Col, Form, Row,
} from 'antd';
import FloatNumber from '../../../../Components/Float/FloatNumber';
import InputSelectGoodForPackage from './InputSelectGoodForPackage';
import { GoodsFormProp } from '../index';
import Addon from '../../../../Components/Float/Addon';
import { useContextForm } from '../../../../Context';

interface PackageItemProps extends GoodsFormProp {
  name: number; // same as index
  restField: {
    fieldKey?: number | undefined;
  };
}

function PackageItem({ name, restField, goodsForm }: PackageItemProps) {
  const form = useFormInstance();
  const { currency, setCurrency } = useContextForm();

  const currencyWatch = Form.useWatch(['packages', name, 'currency'], form);

  useEffect(() => {
    if (currency !== currencyWatch) {
      form.setFieldValue(['packages', name, 'currency'], currency);
    }
  }, [currency]);

  return (
    <div>
      <Row gutter={[24, 24]}>
        <Col sm={24} md={12} xs={24}>
          <Form.Item
            name={[name, 'quantity']}
            rules={[{ required: true, message: 'Please enter quantity.' }]}
            {...restField}
          >
            <FloatNumber label="Number of Packages" min={1} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Row gutter={12}>
            {['Length', 'Width', 'Height']
              .map((item) => (
                <Col span={8} key={item}>
                  <Form.Item
                    name={[name, item.toLowerCase()]}
                    normalize={(value) => value && Number.parseFloat(value.toFixed(2))}
                  >
                    <FloatNumber label={item} min={0} addonAfter="cm" />
                  </Form.Item>
                </Col>
              ))}
          </Row>
        </Col>

        <Col span={24}>
          <InputSelectGoodForPackage
            index={name}
            name={[name, 'items']}
            required
            goodsForm={goodsForm}
          />
        </Col>

        <Col sm={24} md={12} xs={24}>
          <Form.Item name={[name, 'weight']}>
            <FloatNumber label="Weight" min={0} addonAfter="kg" />
          </Form.Item>
        </Col>
        <Col sm={24} md={12} xs={24}>
          <Form.Item name={[name, 'value']}>
            <Addon
              addonName="currency"
              label="Value"
              formName={name}
              min={0}
              options={['EUR', 'USD', 'CHF']?.map((item) => ({ value: item, label: item })) || []}
              onAddonChange={(value) => { setCurrency(value); }}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}

export default PackageItem;
