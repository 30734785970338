import { useEffect, useState } from 'react';
import {
  Typography,
  Form,
  Button,
  Flex,
  Collapse,
  FormInstance,
} from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import clsx from 'clsx';
import { useContextForm } from '../../../Context';
import { PackagesData } from '../../../../../../hooks/api/order';
import { useMessageError } from '../../../../../../hooks/common';
import PackageItem from './PackageItem';
import {
  calcTotalPackages, calcTotalProducts, calcTotalValue, calcTotalWeight,
  CollapseCount, CollapsePrice, CollapseQuantity, CollapseSizes, CollapseTotalWeight,
} from './PackageItem/helpers';

import styles from '../Goods/index.module.scss';

export interface GoodsFormProp {
  goodsForm: FormInstance;
}

interface PackagesFormProps extends GoodsFormProp {
  form: FormInstance;
  isValid: boolean;
  setIsValid: (valid: boolean) => void;
}

const defaultValues: Partial<PackagesData> = {
  quantity: 1,
};

export default function PackagesForm({
  form, isValid, setIsValid, goodsForm,
}: PackagesFormProps): React.ReactNode | null {
  const { formData } = useContextForm();
  const values: { packages: PackagesData[] } = Form.useWatch([], form) || { packages: [] };

  const [activeKey, setActiveKey] = useState<string | string[]>('0');

  useEffect(() => {
    setTimeout(() => form.setFieldsValue({ packages: formData?.packages || [] }), 100);
  }, [formData?.packages]);

  const onFieldsChange = (_: any, allFields: any[]) => {
    if (allFields.some((field) => field.errors.length)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const getHeader = (key: number, remove: (i: number) => void) => (
    <div className={styles.header}>
      <div className={styles.headerKey}>{`#${key + 1}`}</div>
      {(!activeKey?.[0]
        || (activeKey?.[0] && Number.parseInt(activeKey[0], 10) !== key)) && (
        <>
          <div className={styles.count}>
            {/* {values.packages?.[key]?.quantity && `${values.packages?.[key]?.quantity}x`} */}
            <CollapseQuantity index={key} form={form} />
          </div>
          <div className={styles.headerName}>
            <CollapseSizes index={key} form={form} />
            {/* {
              `${values.packages?.[key]?.length || '-'} x ${values.packages?.[key]?.width || '-'
              } x ${values.packages?.[key]?.height || '-'} cm`
            } */}
          </div>
          <div className={clsx(styles.descriptions, 'truncate')}>
            <CollapseCount index={key} form={form} />
            {/* {values.packages?.[key]?.descriptionGoods || values.packages?.[key]?.description} */}
          </div>
          <div className={styles.itemRight}>
            <CollapsePrice index={key} form={form} />
          </div>
          <div className={styles.itemRight}>
            <CollapseTotalWeight index={key} form={form} />
          </div>
        </>
      )}
      <div className={styles.remove}>
        <DeleteFilled onClick={() => remove(key)} />
      </div>
    </div>
  );

  useMessageError([]);

  return (
    <div className={styles.wrapper} style={{ marginBottom: '28px' }}>
      <Form
        onFieldsChange={onFieldsChange}
        form={form}
        autoComplete="off"
        className={styles.form}
        layout="vertical"
      >
        <Typography.Title level={2}>List of packages</Typography.Title>
        <Typography.Paragraph className={styles.required}>
          <span className="text-danger">*</span>
          – fields are required
        </Typography.Paragraph>

        <Form.List name="packages">
          {(fields, { add, remove }) => (
            <>
              <Collapse
                onChange={(key) => setActiveKey(key)}
                defaultActiveKey={[0]}
                activeKey={activeKey}
                accordion
                className={styles.collapse}
              >
                {fields.map(({ key, name, ...restFields }) => (
                  <Collapse.Panel
                    header={getHeader(name, remove)}
                    key={name}
                    className={styles.panel}
                  >
                    <PackageItem
                      key={key}
                      name={name}
                      restField={restFields}
                      goodsForm={goodsForm}
                    />
                  </Collapse.Panel>
                ))}
              </Collapse>
              <Flex className={styles.btnWrap} justify="flex-end">
                <Form.Item>
                  <Button
                    disabled={!isValid && fields.length > 0}
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => {
                          add(defaultValues);
                          setActiveKey([fields?.length?.toString()]); // values.packages?.length.toString()
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                  >
                    ADD ITEM
                  </Button>
                </Form.Item>
              </Flex>
            </>
          )}
        </Form.List>
        <div className={styles.review}>
          <div />

          <div className={styles.rowToRight}>
            {[
              { label: 'Total packages', value: calcTotalPackages(values.packages) },
              { label: 'Total products', value: calcTotalProducts(values.packages) },
              { label: 'Total weight', value: ` ${(calcTotalWeight(values.packages) || 0).toFixed(3)} kg` },
              {
                label: 'Total value',
                value: ` ${calcTotalValue(values.packages)} ${values.packages?.[0]?.currency || ''}`,
              },
            ].map(({ label, value }) => (
              <div key={label}>
                {label}
                {': '}
                <b>{value}</b>
              </div>
            ))}
          </div>
        </div>
      </Form>
    </div>
  );
}
